import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import "../../styles/sfforms.css"

const SalesforceRma = (props) => {

  const captchaInputRef = useRef(null);
  const captchaSettingsRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log(captchaInputRef.current.captcha)
      function timestamp() {
        const response = captchaInputRef.current.captcha.querySelectorAll(".g-recaptcha-response")[0];
        if (response == null || response.value.trim() === "") {
          const captchaElem = captchaSettingsRef.current;
          if (captchaElem && captchaElem.value) {
            try {
              const elems = JSON.parse(captchaElem.value);
              elems["ts"] = JSON.stringify(new Date().getTime());
              captchaElem.value = JSON.stringify(elems);
            } catch (e) {
              console.error("Failed to parse captcha_settings:", e);
            }
          }
        }
      }
    
      setInterval(timestamp, 500);
    }
    
    }, []); 

  return (
    <section id="rma-request" className="salesforceRma bg-black block-vw--top block-vw--btm">
      <div className="wrapper wrapper-md">
        <div className="d-lg-flex">
          <div className="col-12 col-sm-9 col-lg-5">
            {props.content.heading && (
              <h3 className="headline-2 font-w-300" dangerouslySetInnerHTML={{ __html: props.content.heading }} />
            )}
            {props.content.content && (
              <div className="margin-top--32 color-white-50" dangerouslySetInnerHTML={{ __html: props.content.content }} />
            )}
            {props.content.contactEmail && (
              <div className="margin-top--8">
                <a href={"mailto:" + props.content.contactEmail} className="link-arrow color-white">{props.content.contactEmail}</a>
              </div>
            )}
          </div>
          <div className="col-md-6 offset-md-1 margin-top-50-md main-contact">
            <form 
              action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" 
              method="POST" 
              className="sfform"
              onSubmit={(e) => {
                const response = captchaInputRef.current?.getValue();
                if (!response) {
                  e.preventDefault();
                  alert("Please complete the reCAPTCHA.");
                }
              }}
              >
              <div className="form-fields">
                <input ref={captchaSettingsRef} type="hidden" name='captcha_settings' value='{"keyname":"Website","fallback":"true","orgId":"00D80000000cfoR","ts":""}' />
                <input type="hidden" name="orgid" value="00D80000000cfoR" />
                <input type="hidden" name="retURL" value="https://www.nanolumens.com/support-form-confirmation" />

                <div className="form-field">
                    <label for="name">Contact Name</label>
                    <input id="name" maxlength="80" name="name" size="20" type="text" placeholder="Contact Name" />
                </div>

                <div className="form-field">
                    <label for="company">Company</label>
                    <input id="company" maxlength="80" name="company" size="20" type="text" placeholder="Company" />
                </div>

                <div className="form-field">
                    <label for="email">Email</label>
                    <input id="email" maxlength="80" name="email" size="20" type="text" placeholder="Email" />
                </div>

                <div className="form-field">
                    <label for="phone">Phone</label>
                    <input id="phone" maxlength="40" name="phone" size="20" type="text" placeholder="Phone" />
                </div>

                <div className="form-field">
                    <label for="subject">Subject</label>
                    <input id="subject" maxlength="80" name="subject" size="20" type="text" placeholder="Subject" />
                </div>

                <div className="form-field">
                    <label for="description">Description</label>
                    <textarea name="description" placeholder="Description"></textarea>
                </div>

                <div className="form-field">
                    <label for="00N8b00000IkxsQ">LED Module Serial Number</label>
                    <input id="00N8b00000IkxsQ" maxlength="30" name="00N8b00000IkxsQ" size="20" type="text" placeholder="LED Module Serial Number" />
                </div>

                <div className="form-field">
                    <label for="00N8b00000IJi28">Return Street</label>
                    <input id="00N8b00000IJi28" maxlength="40" name="00N8b00000IJi28" size="20" type="text" placeholder="Return Street" />
                </div>

                <div className="form-field">
                    <label for="00N8b00000IJi2D">Return City</label>
                    <input id="00N8b00000IJi2D" maxlength="20" name="00N8b00000IJi2D" size="20" type="text" placeholder="Return City" />
                </div>

                <div className="form-field">
                    <label for="00N8b00000IJi2I">Return Zip/Postal Code</label>
                    <input id="00N8b00000IJi2I" maxlength="10" name="00N8b00000IJi2I" size="20" type="text" placeholder="Return Zip/Postal Code" />
                </div>

                <div className="form-field">
                    <label for="00N8b00000IJi2N">Return State</label>
                    <input id="00N8b00000IJi2N" maxlength="20" name="00N8b00000IJi2N" size="20" type="text" placeholder="Return State" />
                </div>

                <div className="form-field">
                    <label for="00N8b00000IJi2S">Return Country</label>
                    <input id="00N8b00000IJi2S" maxlength="20" name="00N8b00000IJi2S" size="20" type="text" placeholder="Return Country" />
                </div>

                <input type="hidden" id="recordType" name="recordType" value="0128b0000008qlp" />

                <input type="hidden" id="external" name="external" value="1" />
                <div className="form-field">
                  <ReCAPTCHA
                    sitekey="6LeutOgqAAAAAI5vi60rDi8tr0VRQjeg0vXVeSmI"
                    isolated={true}
                    ref={captchaInputRef}
                  />
                </div>

                <div className="form-field">
                  <input type="submit" value="Submit" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SalesforceRma