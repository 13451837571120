import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import "../../styles/sfforms.css"

const SalesforceSupport = (props) => {

  const captchaInputRef = useRef(null);
  const captchaSettingsRef = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log(captchaInputRef.current.captcha)
      function timestamp() {
        const response = captchaInputRef.current.captcha.querySelectorAll(".g-recaptcha-response")[0];
        if (response == null || response.value.trim() === "") {
          const captchaElem = captchaSettingsRef.current;
          if (captchaElem && captchaElem.value) {
            try {
              const elems = JSON.parse(captchaElem.value);
              elems["ts"] = JSON.stringify(new Date().getTime());
              captchaElem.value = JSON.stringify(elems);
            } catch (e) {
              console.error("Failed to parse captcha_settings:", e);
            }
          }
        }
      }
    
      setInterval(timestamp, 500);
    }
    
    }, []); 

  return (
    <section id="support-request" className="salesforceRma bg-black block-vw--top block-vw--btm">
      <div className="wrapper wrapper-md">
        <div className="d-lg-flex">
          <div className="col-12 col-sm-9 col-lg-5">
            {props.content.heading && (
              <h3 className="headline-2 font-w-300" dangerouslySetInnerHTML={{ __html: props.content.heading }} />
            )}
            {props.content.content && (
              <div className="margin-top--32 color-white-50" dangerouslySetInnerHTML={{ __html: props.content.content }} />
            )}
            {props.content.contactEmail && (
              <div className="margin-top--8">
                <a href={"mailto:" + props.content.contactEmail} className="link-arrow color-white">{props.content.contactEmail}</a>
              </div>
            )}
          </div>
          <div className="col-md-6 offset-md-1 margin-top-50-md main-contact">
            <form 
              action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" 
              className="sfform" 
              method="POST"
              onSubmit={(e) => {
                const response = captchaInputRef.current?.getValue();
                if (!response) {
                  e.preventDefault();
                  alert("Please complete the reCAPTCHA.");
                }
              }}
              >
              <div className="form-fields">
                <input ref={captchaSettingsRef} type="hidden" name='captcha_settings' value='{"keyname":"Website","fallback":"true","orgId":"00D80000000cfoR","ts":""}' />
                <input type="hidden" name="orgid" value="00D80000000cfoR" />
                <input type="hidden" name="retURL" value="https://www.nanolumens.com/support-form-confirmation" />

                {/*  ----------------------------------------------------------------------  */}
                {/*  NOTE: These fields are optional debugging elements. Please uncomment    */}
                {/*  these lines if you wish to test in debug mode.                          */}
                {/*  <input type="hidden" name="debug" value={1} />                          */}
                {/*  <input type="hidden" name="debugEmail" value="yana@rithom.com" />       */}
                {/*  ----------------------------------------------------------------------  */}

                <div className="form-field">
                    <label htmlFor="name">Contact Name</label>
                    <input id="name" maxLength="80" name="name" size="20" type="text" placeholder="Contact Name" />
                </div>

                <div className="form-field">
                    <label htmlFor="email">Email</label>
                    <input id="email" maxLength="80" name="email" size="20" type="text" placeholder="Email" />
                </div>

                <div className="form-field">
                    <label htmlFor="phone">Phone</label>
                    <input id="phone" maxLength="40" name="phone" size="20" type="text" placeholder="Phone" />
                </div>

                <div className="form-field">
                    <label htmlFor="company">Company</label>
                    <input id="company" maxLength="80" name="company" size="20" type="text" placeholder="Company" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IJi1o">Display Street</label>
                    <input id="00N8b00000IJi1o" maxLength="80" name="00N8b00000IJi1o" size="20" type="text" placeholder="Display Street" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IJi1t">Display City</label>
                    <input id="00N8b00000IJi1t" maxLength="20" name="00N8b00000IJi1t" size="20" type="text" placeholder="Display City" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IJi1y">Display Zip/Postal Code</label>
                    <input id="00N8b00000IJi1y" maxLength="20" name="00N8b00000IJi1y" size="20" type="text" placeholder="Display Zip/Postal Code" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IJi23">Display State</label>
                    <input id="00N8b00000IJi23" maxLength="20" name="00N8b00000IJi23" size="20" type="text" placeholder="Display State" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IJi24">Display Country</label>
                    <input id="00N8b00000IJi24" maxLength="20" name="00N8b00000IJi24" size="20" type="text" placeholder="Display Country" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IlCyn">Display Location</label>
                    <input id="00N8b00000IlCyn" maxLength="40" name="00N8b00000IlCyn" size="20" type="text" placeholder="Display Location" />
                </div>

                <div className="form-field">
                    <label htmlFor="subject">Subject</label>
                    <input id="subject" maxLength="80" name="subject" size="20" type="text" placeholder="Subject" />
                </div>

                <div className="form-field">
                    <label htmlFor="description">Description</label>
                    <textarea name="description" placeholder="Description"></textarea>
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000Ikxsa">Inform spare stock levels on-site</label>
                    <textarea id="00N8b00000Ikxsa" name="00N8b00000Ikxsa" rows="3" type="text" wrap="soft" placeholder="Inform spare stock levels on-site"></textarea>
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IkxrX">Nixel</label>
                    <input id="00N8b00000IkxrX" name="00N8b00000IkxrX" size="20" type="text" placeholder="Nixel" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000Ikxrh">Receiving Cards</label>
                    <input id="00N8b00000Ikxrh" name="00N8b00000Ikxrh" size="20" type="text" placeholder="Receiving Cards" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000Ikxrr">Power Supply</label>
                    <input id="00N8b00000Ikxrr" name="00N8b00000Ikxrr" size="20" type="text" placeholder="Power Supply" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000Ikxs6">NVIU#</label>
                    <input id="00N8b00000Ikxs6" maxLength="30" name="00N8b00000Ikxs6" size="20" type="text" placeholder="NVIU#" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IkxsL">Asset ID</label>
                    <input id="00N8b00000IkxsL" maxLength="20" name="00N8b00000IkxsL" size="20" type="text" placeholder="Asset ID" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IkxsQ">LED Module Serial Number</label>
                    <input id="00N8b00000IkxsQ" maxLength="30" name="00N8b00000IkxsQ" size="20" type="text" placeholder="LED Module Serial Number" />
                </div>

                <div className="form-field">
                    <label htmlFor="00N8b00000IkxsV">Spare Kit SN</label>
                    <input id="00N8b00000IkxsV" maxLength="20" name="00N8b00000IkxsV" size="20" type="text" placeholder="Spare Kit SN" />
                </div>

                <input type="hidden"  id="external" name="external" value="1" />
                <div className="form-field">
                  <ReCAPTCHA
                    sitekey="6LeutOgqAAAAAI5vi60rDi8tr0VRQjeg0vXVeSmI"
                    isolated={true}
                    ref={captchaInputRef}
                  />
                </div>
                
                <div className="form-field">
                  <input type="submit" value="Submit" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SalesforceSupport